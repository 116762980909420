<template>
  <div
    v-if="ConfigComponent"
    :id="campaign.id"
    ref="container"
    class="scroll-mt-3xl"
  >
    <div class="mb-sm">
      <div class="max-w-[930px] m-auto px-md sm:px-0">
        <Headline
          v-if="!isInEditDialog"
          :headline="useCampaignConfiguratorHeadline(campaign)"
          level="h2"
          has-decoration
          class="mt-[53px] mb-[23px]"
        />
        <p v-if="campaign.description" class="text-center">
          {{ campaign.description }}
        </p>
      </div>
    </div>
    <ConfigComponent
      :campaign="campaign"
      :line-item="lineItem"
      :product="product"
      @add-to-cart="addToCart"
    />
  </div>
</template>
<script setup lang="ts">
import { Headline } from '@/complib';
import type { Campaign } from '~/server/transformers/shop/campaignTransformer';
import { campaignImports } from './configurators/importMap';
import { useCampaignConfiguratorHeadline } from '~/components/page/product/composables/useCampaignConfiguratorHeadline';
import { useDialogStore } from '~/stores/useDialogStore';
import type { LineItem } from '~/server/transformers/shop/lineItemTransformer';
import type { Product } from '~/server/transformers/shop/product/types';
import type { AddCampaignEvent } from '~/stores/useCart';

const props = defineProps({
  campaign: {
    type: Object as PropType<Campaign>,
    required: true,
  },
  lineItem: {
    type: Object as PropType<LineItem>,
    required: false,
    default: null,
  },
  product: {
    type: Object as PropType<Product>,
    required: false,
    default: null,
  },
  isInEditDialog: {
    type: Boolean,
    required: false,
    default: false,
  },
  isInActionPageDialog: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'addToCart', items: AddCampaignEvent['items'], quantity: number): void;
}>();

function addToCart(items: AddCampaignEvent['items'], quantity: number) {
  emit('addToCart', items, quantity);
}

const container = ref<HTMLElement | null>(null);
const dialogStore = useDialogStore();
props.isInEditDialog &&
  dialogStore.setHeadline(useCampaignConfiguratorHeadline(props.campaign));

const ConfigComponent = campaignImports[props.campaign.type];
</script>
