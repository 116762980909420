import { CampaignType } from '~/@types/campaign';
import type { Campaign } from '~/server/transformers/shop/campaignTransformer';

export function useCampaignConfiguratorHeadline(campaign: Campaign) {
  const { t } = useTrans();
  return t(...map[campaign.type](campaign));
}

const map: Partial<
  Record<
    CampaignType,
    (campaign: Campaign) => Parameters<ReturnType<typeof useTrans>['t']>
  >
> = {
  [CampaignType.staticXPlusY]: (campaign) => {
    return [
      'shop.campaign.configurator.staticXPlusY.headline',
      {
        TOTAL: campaign.quantity,
        REDUCED: campaign.quantity - campaign.freeQuantity,
      },
    ];
  },
  [CampaignType.dynamicXPlusY]: (campaign) => {
    return [
      'shop.campaign.configurator.dynamicXPlusY.headline',
      {
        TOTAL: campaign.quantity,
        REDUCED: campaign.quantity - campaign.freeQuantity,
        VARIANTS: campaign.products.length,
      },
    ];
  },
  [CampaignType.staticPercentageDiscount]: (campaign) => {
    return [
      'shop.campaign.configurator.staticPercentageDiscount.headline',
      {
        QUANTITY: campaign.quantity,
        PERCENTAGE: campaign.percentageDiscount,
      },
    ];
  },
  [CampaignType.dynamicPercentageDiscount]: (campaign) => {
    return [
      'shop.campaign.configurator.dynamicPercentageDiscount.headline',
      {
        PERCENTAGE: campaign.percentageDiscount,
      },
    ];
  },
  [CampaignType.fixedPercentageDiscount]: (campaign) => {
    return [
      'shop.campaign.configurator.fixedPercentageDiscount.headline',
      {
        PERCENTAGE: campaign.percentageDiscount,
      },
    ];
  },
  [CampaignType.staticAbsoluteDiscount]: (campaign) => {
    return [
      'shop.campaign.configurator.staticAbsoluteDiscount.headline',
      {
        QUANTITY: campaign.quantity,
        DISCOUNT: campaign.absoluteDiscount,
      },
    ];
  },
  [CampaignType.dynamicAbsoluteDiscount]: (campaign) => {
    return [
      'shop.campaign.configurator.dynamicAbsoluteDiscount.headline',
      {
        DISCOUNT: campaign.absoluteDiscount,
      },
    ];
  },
  [CampaignType.fixedAbsoluteDiscount]: (campaign) => {
    return [
      'shop.campaign.configurator.fixedAbsoluteDiscount.headline',
      {
        DISCOUNT: campaign.absoluteDiscount,
      },
    ];
  },
  [CampaignType.fixedBaseFixedFree]: () => {
    return ['shop.campaign.configurator.fixedBaseFixedFree.headline'];
  },
  [CampaignType.variableBaseFixedFree]: () => {
    return ['shop.campaign.configurator.variableBaseFixedFree.headline'];
  },
  [CampaignType.fixedBaseVariableFree]: (campaign) => {
    const item = campaign.products.find((product) => !product.isFree);

    return [
      'shop.campaign.configurator.fixedBaseVariableFree.headline',
      {
        FREE_QUANTITY: campaign.freeQuantity,
        count: campaign.products.filter((product) => !product.isFree).length,
        PRODUCT: item?.product?.name ?? item.name ?? '',
      },
    ];
  },
};
