import { CampaignType } from '~/@types/campaign';

export const campaignImports: Partial<
  Record<CampaignType, ReturnType<typeof defineAsyncComponent>>
> = {
  [CampaignType.dynamicXPlusY]: defineAsyncComponent(
    () => import('./dynamicXPlusY.vue'),
  ),
  [CampaignType.dynamicPercentageDiscount]: defineAsyncComponent(
    () => import('./dynamicPercentageDiscount.vue'),
  ),
  [CampaignType.fixedPercentageDiscount]: defineAsyncComponent(
    () => import('./fixedPercentageDiscount.vue'),
  ),
  [CampaignType.dynamicAbsoluteDiscount]: defineAsyncComponent(
    () => import('./dynamicAbsoluteDiscount.vue'),
  ),
  [CampaignType.fixedAbsoluteDiscount]: defineAsyncComponent(
    () => import('./fixedAbsoluteDiscount.vue'),
  ),
  [CampaignType.variableBaseFixedFree]: defineAsyncComponent(
    () => import('./variableBaseFixedFree.vue'),
  ),
  [CampaignType.fixedBaseVariableFree]: defineAsyncComponent(
    () => import('./fixedBaseVariableFree.vue'),
  ),
  [CampaignType.staticXPlusY]: defineAsyncComponent(
    () => import('./staticXPlusY.vue'),
  ),
  [CampaignType.staticPercentageDiscount]: defineAsyncComponent(
    () => import('./staticPercentageDiscount.vue'),
  ),
  [CampaignType.staticAbsoluteDiscount]: defineAsyncComponent(
    () => import('./staticAbsoluteDiscount.vue'),
  ),
  [CampaignType.fixedBaseFixedFree]: defineAsyncComponent(
    () => import('./fixedBaseFixedFree.vue'),
  ),
};
